import React from 'react'
import './wrapperPrivacy.css'
function WrapperPrivacy() {
  return (
    <section className='privacyPolicWrap'>
      <div className='container'>

      </div>
    </section>
  )
}

export default WrapperPrivacy
