import React, { useEffect, useState } from 'react'
import Breadcrums from '../../common/breadcrums/Breadcrums'


// import { AiOutlineHeart } from "react-icons/ai";
// import { BiShoppingBag } from "react-icons/bi";
import "react-rater/lib/react-rater.css";
import ProductItem from './productItem/ProductItem';
import ProductionsDescriptions from './productsDescriptions/ProductionsDescriptions';
import { FaLongArrowAltRight } from 'react-icons/fa';
import axios from 'axios';
import { base_url } from '../../server';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

function ProductDetails() {
    const params = useParams()
    const [detailsData, setDetailsData] = useState(null)
    const [errMessage, setErorMessage] = useState(false)
    const getProducDetailsData = async () => {
        try {
            const response = await axios.get(`${base_url}product/public/${params.uid}`, { withCredentials: true, })
            setDetailsData(response.data)
            setErorMessage(false)
        } catch (error) {
            setErorMessage(error.message)
        }
    }
    useEffect(() => {
        getProducDetailsData()
    }, [])
    const breadcrumsTitle = {
        id: '1', title: detailsData?.product?.name, /* icon1: <FaLongArrowAltRight />, productTitle: '' */
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    return (
        <div>
            <div className=''>
                <Breadcrums breadcrumsTitle={breadcrumsTitle} />
            </div>
            {errMessage && <Alert variant="filled" severity="error" className='w-50 m-auto my-4'>
                {errMessage}
            </Alert>}
            {!errMessage &&
                <div className='container'>
                    <ProductItem productDetails={detailsData} />
                </div>}
            {!errMessage &&
                <section>
                    <ProductionsDescriptions productDetails={detailsData} />
                </section>}
        </div>

    )
}

export default ProductDetails
