import React, { useEffect, useRef, useState } from 'react';

import styled from "styled-components";


import './MultiProductsItems.css'
// hover Zoom Functions 

const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  padding: 50px;
  // border: 1px solid #00adb7;
  border-radius: 15px;
  width:100%;
  :hover {
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.55), 0 14px 18px rgba(0, 0, 0, 0.55);
  }
`;

const Image = styled.img.attrs((props) => ({
  src: props.source
}))``;

const Target = styled(Image)`
  position: absolute;
  left: ${(props) => props.offset.left}px;
  top: ${(props) => props.offset.top}px;
  opacity: ${(props) => props.opacity};
`;

function MultiProductsItems({ productDetails }) {
  const proGalleryImage = productDetails
  const productImage = proGalleryImage?.product?.variations[0].images
  const productImagetest = proGalleryImage?.product
  console.log(productImagetest);

  const [selectedImage, setSelectedImage] = useState()
  // const [allImage, setSAllImage] = useState(productImage)
  useEffect(() => {
    setSelectedImage(productDetails?.product?.variations[0].images[0]?.url)
  }, [productDetails])
  // hover Zoom Functions 

  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  const containerRef = useRef(null);

  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleMouseMove = (e) => {
    const targetRect = targetRef.current.getBoundingClientRect();
    const sourceRect = sourceRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
    const yRatio =
      (targetRect.height - containerRect.height) / sourceRect.height;

    const left = Math.max(
      Math.min(e.pageX - sourceRect.left, sourceRect.width),
      0
    );
    const top = Math.max(
      Math.min(e.pageY - sourceRect.top, sourceRect.height),
      0
    );

    setOffset({
      left: left * -xRatio,
      top: top * -yRatio
    });
  };


  return (
    <div className='col-md-6 col-lg-6 col-12 shadow bg-body rounded'>
      <div className='row p-2 h-100'>
        <div className='col-md-2 col-lg-2 col-2'>
          <div className='d-flex align-items-center h-100'>
          <div className='my-auto'>
            {productImage?.map((img) => {
              return <div className='cursor-pointer'>
                <figure>
                  <img src={img?.url} alt='zoomPro'
                    onClick={() => { setSelectedImage(img?.url) }}
                    width={100 + '%'}
                    style={img?.url === selectedImage ? { border: 'solid 1px #107ae33d', padding: "5px", } : {}}
                  />
                </figure>
              </div>
            })}
          </div>
          </div>
        </div>



        {/*  */}
        <div className='col-md-10 col-lg-10 col-10'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <Container
              ref={containerRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
            >
              <Image ref={sourceRef} alt="source" source={selectedImage}  className='h-100 w-100 rounded' />
              <Target
                ref={targetRef}
                alt="target"
                opacity={opacity}
                offset={offset}
                source={selectedImage}
              />
            </Container>
          </div>
        </div>
        {/*  */}

        {/* <figure className=''>
            <img src={selectedImage} alt='zoomPro' width={100 + '%'} />
          </figure> */}
      </div>
    </div>

  )
}

export default MultiProductsItems
