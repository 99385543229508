import React from 'react'

import './BlogLeaveComment.css'
import { FloatingLabel, Form } from 'react-bootstrap'
import { Button } from '@mui/material'
function BlogLeaveComment() {
    const handleOnChange = () => {

    }
    return (
        <>
            <div className='row py-5 blogInfo mb-3'>
                <div className='col-12'>
                    <p>FTH (FTH Electronics L.L.C Dubai) is U.A.E’s leading provider of “Restaurant Wireless paging System” and services across the region since 2005 specializing mainly in Restaurant on-site paging systems & wireless communication devices.

                    </p>
                    <span><b>Leave Comments</b></span>
                    <p>Your email address will not be published. Required fields are marked *</p>
                </div>
                <div className='col-12'>
                    <Form.Label htmlFor="inputPassword5"><b>COMMENTS </b></Form.Label>
                    <FloatingLabel
                        controlId="floatingTextarea"
                        label="MESSAGES"
                        className='rounded-0 mb-3'

                    >
                        <Form.Control as="textarea" /* onChange={handleInput} */
                            name='comments' placeholder="" style={{ height: '85px' }} onChange={handleOnChange} />
                    </FloatingLabel>
                </div>

                <div className=' col-12'>
                    <Form.Label htmlFor="inputPassword5">NAME</Form.Label>
                    <Form.Control
                        type="text"
                        aria-describedby="passwordHelpBlock"
                        className='rounded-0 mb-3'
                        // onChange={handleInput}
                        name='phone'
                        placeholder='Enter Your Name'
                        onChange={handleOnChange}
                    />

                </div>
                <div className=' col-12'>
                    <Form.Label htmlFor="inputPassword5">WEBSITE</Form.Label>
                    <Form.Control
                        type="text"
                        aria-describedby="passwordHelpBlock"
                        className='rounded-0 mb-3'
                        // onChange={handleInput}
                        name='subject'
                        placeholder='Enter Your Website URL'
                        onChange={handleOnChange}
                    />
                </div>
                <div className='col-12'>
                    <Button variant='contained' >Post Comments</Button>
                </div>
            </div>
        </>
    )
}

export default BlogLeaveComment
