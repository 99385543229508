import React from 'react'
import BestSellingProducts from '../bestSellingItem/BestSellingProducts'
import ProductsCrums from '../newArrivals/productscrums/ProductsCrums'

function BestSelling() {
    const breadCrumsTittle  = 'BEST SELLING'
  return (
    <>
    <ProductsCrums data = {breadCrumsTittle}/>
      <BestSellingProducts/>
    </>
  )
}

export default BestSelling
