import React from 'react'
import { Link } from 'react-router-dom';
import { BsCartDashFill } from "react-icons/bs"
// import { AiFillHeart } from "react-icons/ai"
// skick Crausel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Counter from '../../../common/counter/Counter';

// css----
import './BestSellingProducts.css'
// image----

import product1 from '../../../assets/img/bestSellingProducts/bestSelling1.jpg'
import product2 from '../../../assets/img/bestSellingProducts/bestSelling2.jpg'
import product3 from '../../../assets/img/bestSellingProducts/bestSelling3.jpg'
import product4 from '../../../assets/img/bestSellingProducts/bestSelling4.jpg'
import product5 from '../../../assets/img/bestSellingProducts/bestSelling3.jpg'


function BestSellingProducts() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='container-fluid'>
        <Slider {...settings}>
          <div className=''>
            {/* {relatedProducts.map(()=>{})} */}
            <div className='p-2'>
              <div className='productsCard'>
                <figure>
                  <Link to={'#'}>
                    <img src={product1} alt='products' width={65 + '%'} />
                  </Link>
                </figure>
                <div className='productTitle p-3'>

                  <div className='text-truncate' >
                    <span className='text-dark'>
                      <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                    </span>
                  </div>
                  <div className='price d-flex'>
                    <span>AED 29996</span>
                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>AED 59996</span>
                    <div className='sci  d-flex' >
                      <Counter />
                      <span className='addToCart'>
                        <BsCartDashFill />
                      </span>
                      {/* <span className='addToCart'>
                      <AiFillHeart />
                    </span> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div>
            <div className='p-2'>
              <div className='productsCard'>
                <figure>
                  <Link to={'#'}>
                    <img src={product2} alt='products' width={65 + '%'} />
                  </Link>
                </figure>
                <div className='productTitle p-3'>

                  <div className='text-truncate' >
                    <span className='text-dark'>
                      <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                    </span>
                  </div>
                  <div className='price d-flex'>
                    <span>AED 29996</span>
                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>AED 59996</span>
                    <div className='sci  d-flex' >
                      <Counter />
                      <span className='addToCart'>
                        <BsCartDashFill />
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div>
            <div className='p-2'>
              <div className='productsCard'>
                <figure>
                  <Link to={'#'}>
                    <img src={product3} alt='products' width={65 + '%'} />
                  </Link>
                </figure>
                <div className='productTitle p-3'>

                  <div className='text-truncate' >
                    <span className='text-dark'>
                      <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                    </span>
                  </div>
                  <div className='price d-flex'>
                    <span>AED 29996</span>
                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>AED 59996</span>
                    <div className='sci  d-flex' >
                      <Counter />
                      <span className='addToCart'>
                        <BsCartDashFill />
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div>
            <div className='p-2'>
              <div className='productsCard'>
                <figure>
                  <Link to={'#'}>
                    <img src={product4} alt='products' width={65 + '%'} />
                  </Link>
                </figure>
                <div className='productTitle p-3'>

                  <div className='text-truncate' >
                    <span className='text-dark'>
                      <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                    </span>
                  </div>
                  <div className='price d-flex'>
                    <span>AED 29996</span>
                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>AED 59996</span>
                    <div className='sci  d-flex' >
                      <Counter />
                      <span className='addToCart'>
                        <BsCartDashFill />
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div>
            <div className='p-2'>
              <div className='productsCard'>
                <figure>
                  <Link to={'#'}>
                    <img src={product5} alt='products' width={65 + '%'} />
                  </Link>
                </figure>
                <div className='productTitle p-3'>

                  <div className='text-truncate' >
                    <span className='text-dark'>
                      <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                    </span>
                  </div>
                  <div className='price d-flex'>
                    <span>AED 29996</span>
                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>AED 59996</span>
                    <div className='sci  d-flex' >
                      <Counter />
                      <span className='addToCart'>
                        <BsCartDashFill />
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div>
            <div className='p-2'>
              <div className='productsCard'>
                <figure>
                  <Link to={'#'}>
                    <img src={product4} alt='products' width={65 + '%'} />
                  </Link>
                </figure>
                <div className='productTitle p-3'>

                  <div className='text-truncate' >
                    <span className='text-dark'>
                      <Link to={''} className='text-decoration-none text-truncate'>GT-68 FRS Radio License-free</Link>
                    </span>
                  </div>
                  <div className='price d-flex'>
                    <span>AED 29996</span>
                    <span className='text-muted text-decoration-line-through ps-3' style={{ textDecoration: '' }}>AED 59996</span>
                    <div className='sci  d-flex' >
                      <Counter />
                      <span className='addToCart'>
                        <BsCartDashFill />
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Slider>
    </div>
  )
}

export default BestSellingProducts
