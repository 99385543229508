import React from 'react'
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { AiFillStar } from 'react-icons/ai';
import { Progress, Space } from 'antd';
// image import 
import usa from '../../../../../../assets/img/countryFlag/us.webp'
import './Review.css'
import { Button } from '@mui/material';
const labels: { [index: string]: string } = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};
function Reviews() {
    const value = 4.5;
    return (
        <>
            <div className='col-md-4 col-lg-4 col-12'>
                <div className='m-auto' style={{ width: "fit-content",position:'sticky',top:'120px',transition:'.2s all ese-in-out' }}>
                    <div className='reviewPoint'>
                        <span className='d-flex align-items-center'><h2 className='' style={{ color: "blue", fontSize: "5rem" }}>4.6</h2><span style={{ color: "blue", fontSize: "2rem" }}><strong className='text-muted'>/5</strong></span></span>
                        <span>
                            <Box
                                sx={{
                                    width: 200,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Rating
                                    name="text-feedback"
                                    value={value}
                                    readOnly
                                    precision={0.5}
                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                />
                                <Box sx={{ ml: 2 }}>
                                    <span className='p-1 rounded' style={{ backgroundColor: "blue", color: "#fff" }}>{labels[value]}</span>
                                </Box>

                            </Box>
                            <div className='d-block'>
                                <span style={{ fontSize: "14px" }} className='text-muted'> <b> From 409 reviews</b>
                                </span>
                            </div>
                        </span>
                    </div>
                    <div className='customerRating my-4'>
                        <div className=''>
                            <h5 className='m-0'>
                                <strong>Customers Ratings</strong>
                            </h5>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span className='py-2'><b>5</b></span>
                            <span className='text-muted p-1'>
                                <AiFillStar />
                            </span>
                            <div >
                                <Space direction="vertical">
                                    <Progress percent={82} size={[200, 14]} />
                                </Space>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span className='py-2'><b>4</b></span>
                            <span className='text-muted p-1'>
                                <AiFillStar />
                            </span>
                            <div >
                                <Space direction="vertical">
                                    <Progress percent={62} size={[200, 14]} />
                                </Space>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span className='py-2'><b>3</b></span>
                            <span className='text-muted p-1'>
                                <AiFillStar />
                            </span>
                            <div >
                                <Space direction="vertical">
                                    <Progress percent={50} size={[200, 14]} />
                                </Space>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span className='py-2'><b>2</b></span>
                            <span className='text-muted p-1'>
                                <AiFillStar />
                            </span>
                            <div >
                                <Space direction="vertical">
                                    <Progress percent={40} size={[200, 14]} />
                                </Space>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span className='py-2'><b>1</b></span>
                            <span className='text-muted p-1'>
                                <AiFillStar />
                            </span>
                            <div >
                                <Space direction="vertical">
                                    <Progress percent={10} size={[200, 14]} />
                                </Space>
                            </div>
                        </div>
                    </div>
                    <Button variant="contained" size="medium">
                        Write A Reviews
                    </Button>
                </div>
            </div>
            <div className='col-md-8 col-lg-8 col-12'>
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
                {/* reviews single start */}
                <div className='border rounded py-5 px-3 my-3 shadow-sm '>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-12'>
                            <div className='border-end'>
                                <div className='d-flex'>
                                    <div className=''>
                                        <span className='text-muted'>
                                            <b>
                                                4 years ago
                                                us
                                                D***L
                                            </b>
                                        </span>
                                    </div>
                                    <div className=''>
                                        <span className='p-3'>
                                            <img src={usa} alt='usa' />
                                        </span>
                                    </div>

                                </div>
                                <div className=''>
                                    <span>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="text-feedback"
                                                value={value}
                                                readOnly
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Box>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 col-12'>
                            <div className='p-3 text-muted'>
                                <p>This is great to charge my 6 walkie-talkie s at once instead of 3 at a time</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* reviews single end */}
            </div>
        </>
    )
}

export default Reviews
