import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { base_url } from '../../../server'
import { Alert } from '@mui/material';
function createMarkup(data) {
    return { __html: data };
}
function PrivacyPolicyDeatail() {
    const [privacyPolicy, setPrivacyPolicy] = useState(null)
    const [errorMessage, seterrorMessage] = useState(null)

    const getPrivacyPlicy = async () => {

        try {
            const res = await axios.get(`${base_url}privacyPolicy/singlePrivacyPolicy`, { withCredentials: true })
            setPrivacyPolicy(res.data);
        } catch (error) {
            seterrorMessage(error.errorMessage)
        }
    }
    useEffect(() => {
        getPrivacyPlicy()
    }, [])
    return (
        <section>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-12'>
                        <h3><b>Privacy Policy</b></h3>
                    </div>
                    {errorMessage && <Alert variant="filled" severity="error" className='m-5'>
                        This is an error alert — check it out! &nbsp;&nbsp;&nbsp; {errorMessage}
                    </Alert>}
                    <div className='col-md-12 col-lg-12 col-12' dangerouslySetInnerHTML={createMarkup(privacyPolicy?.description)}>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicyDeatail
