import React from 'react'
import "./DetailsNeedsobj.css"
import DetailsGallery from '../detailsGallery/DetailsGallery'
import DetailsRelatedsProducts from '../relatedProducts/DetailsRelatedsProducts'
function DetailsNeedsobj() {
    return (
        <>
            <div className='needThisRadio'>
                <p>
                    <b>
                        When and where do you need this radio?
                    </b>
                </p>
                <p>
                    - When in the construction area, you need to communicate with your team member efficiently.
                </p>
                <p>
                    - For security guards, you may feel this is a great tool for your work, talks in real time.
                </p>
                <p>
                    - When you are traveling by car with a group of friends, this helps constantly communication among your mates.
                </p>
                <p>
                    <b>
                        When and where do you need this radio?
                    </b>
                </p>
                <p>
                    - Communication Range: about 2-4km (depends on the actual situation).
                </p>
            </div>
            <div className='details-list'>
                <ul className='m-0' style={{ listStyle: 'none' }}>
                    <li>
                        <b>Functions:</b>
                    </li>
                </ul>
                <ol>

                    <li>
                        Frequency: 420-450MHz
                    </li>
                    <li>
                        16 channels
                    </li>
                    <li>
                        1500mAh Li-ion battery
                    </li>
                    <li>
                        50 CTCSS/105 CDCSS
                    </li>
                    <li>
                        VOX Function
                    </li>
                    <li>
                        Voice Prompt
                    </li>
                    <li>
                        PC Programming
                    </li>
                    <li>
                        Emergency Alarm
                    </li>
                    <li>
                        Intelligent Charging
                    </li>
                    <li>
                        Battery Save
                    </li>
                    <li>
                        Low Voltage Alert
                    </li>
                    <li>
                        Time-out Timer
                    </li>
                    <li>
                        Flash Light
                    </li>
                </ol>
            </div>
            <div className='details-list'>
                <ul className='m-0 p-0' style={{ listStyle: 'none' }}>
                    <li>
                        <b>Specifications:
                            :</b>
                    </li>
                    <li>
                        Model: BF-888S
                    </li>
                    <li>
                        Antenna Impedance: 50ohm
                    </li>
                    <li>
                        Battery type: Li-ion battery,1500mAh
                    </li>
                    <li>
                        Battery Life: About 8 hours.
                    </li>
                    <li>
                        Rated Voltage: DC 3.7V
                    </li>
                    <li>
                        Output power: 5W
                    </li>
                    <li>
                        Working temperature: -30°C~60°C (-22℉~140℉)
                    </li>
                    <li>
                        Dual band or single band: single band
                    </li>
                    <li>
                        Dual frequency or Single frequency: Single frequency
                    </li>
                    <li>
                        Frequency Range: UHF: 420-450MHz
                    </li>
                    <li>
                        Memory Channel: 16
                    </li>
                    <li>
                        Functions: CTCSS/DCS, Led torch, Low battery alarm,
                    </li>
                    <li>
                        Power saving, voice prompt, PC software programmable
                    </li>
                </ul>
            </div>
            <div className='details-list py-4'>
                <p><span><b>Antenna Type:</b></span>Compatible with SMA-Female Antenna</p>
                <p><span><b>Earpiece & Programming Type:</b></span> Compatible with K-plug earpiece/cable</p>
            </div>
            <div className='details-list'>
                <ul className='m-0 p-0' style={{ listStyle: 'none' }}>
                    <li>
                        <b>Package Content:</b>
                    </li>
                    <li>
                    2 x BaoFeng BF-888S
                    </li>
                    <li>
                    2 x Earpieces
                    </li>
                    <li>
                    2 x 3.7v 1500mAh Li-ion Battery
                    </li>
                    <li>
                    2 x SMA-Female Antenna
                    </li>
                    <li>
                    2 x Belt Clip
                    </li>
                    <li>
                    2 x Desktop Charger
                    </li>
                    <li>
                    2 x Wrist Strap
                    </li>
                </ul>
            </div>
            <section className=''>
                <DetailsGallery/>
            </section>
            <section>
                <DetailsRelatedsProducts/>
            </section>
        </>
    )
}

export default DetailsNeedsobj
