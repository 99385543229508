import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { base_url } from '../../../server'
import { Alert } from '@mui/material'

function createMarkup(data) {
    return { __html: data };
}
function TermAndCond() {
    const [termCondition, setTermCondition] = useState(null)
    const [errorMessage, seterrorMessage] = useState(null)

    const gettemCondition = async () => {

        try {
            const res = await axios.get(`${base_url}termsCondition/singleTermsAndCondition`, { withCredentials: true })
            setTermCondition(res.data);
        } catch (error) {
            seterrorMessage(error.errorMessage)
        }
    }
    useEffect(() => {
        gettemCondition()
    }, [])
    return (
        <section>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-12'>
                        <h3><b>Term & Condition</b></h3>
                    </div>
                    {errorMessage && <Alert variant="filled" severity="error" className='m-5'>
                    This is an error alert — check it out! &nbsp;&nbsp;&nbsp; {errorMessage}
                </Alert>}
                    <div className='col-md-12 col-lg-12 col-12' dangerouslySetInnerHTML={createMarkup(termCondition?.description)}>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default TermAndCond
