import React from 'react'

// import antDesign 
import './CategoryAside.css'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Layout, Menu, theme } from 'antd';
import tag from '../../assets/img/seriesProducts/tag.png'

const { Sider } = Layout;


const items = { title: 'DIGITAL STANDARD', data: ['Analog', 'DMR'] }
const items1 = { title: 'FREQUENCY', data: ['Analog', 'DMR','Analog', 'DMR',] }
const items2 = { title: 'MAXIMUM OUTPUT POWER', data: ['Analog', 'DMR','Analog', 'DMR','Analog', 'DMR'] }
const items3 = { title: 'FEATURES', data: ['Analog', 'DMR','Analog', 'DMR'] }

function CategoryAside() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                }}
                onCollapse={(collapsed, type) => {
                }}
                style={{  background: colorBgContainer,}}
            >
                {/* <div className="demo-logo-vertical " >
                    <h6>Filter</h6>
                </div> */}


                <div className="demo-logo-vertical " >
                    <div className='bg-dark text-light my-3 rounded px-2 d-flex align-items-center w-100'>
                        {/* <img src={tag} alt='' width={30} height={30}/> */}
                        <h5 >FILTER</h5>
                    </div>
                </div>
                <div className="demo-logo-vertical " >
                    <h6 className='text-decoration-underline'>{'Filter'}</h6>
                </div>
                {items.data?.map((item, i) => {
                    return <Menu>
                        <span className=''><FormGroup>
                            <FormControlLabel control={<Checkbox />} label={item} />
                        </FormGroup></span>
                    </Menu>
                })}
                <div className="demo-logo-vertical " >
                    <h6>{items.title}</h6>
                </div>
                {items1.data?.map((item, i) => {
                    return <Menu>
                        <span className=''><FormGroup>
                            <FormControlLabel control={<Checkbox />} label={item} />
                        </FormGroup></span>
                    </Menu>
                })}
                <div className="demo-logo-vertical " >
                    <h6>{items.title}</h6>
                </div>
                {items2.data?.map((item, i) => {
                    return <Menu>
                        <span className=''><FormGroup>
                            <FormControlLabel control={<Checkbox />} label={item} />
                        </FormGroup></span>
                    </Menu>
                })}
                <div className="demo-logo-vertical " >
                    <h6>{items.title}</h6>
                </div>
                {items3.data?.map((item, i) => {
                    return <Menu>
                        <span className=''><FormGroup>
                            <FormControlLabel control={<Checkbox />} label={item} />
                        </FormGroup></span>
                    </Menu>
                })}

            </Sider>
        </>

    )
}

export default CategoryAside
