import { Button } from '@mui/material'
import React, { useState } from 'react'
import { BiSolidMessageRounded } from 'react-icons/bi'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { FloatingLabel } from 'react-bootstrap';

function ReviewQuestions() {
    const [show, setShow] = useState(false)
    return (
        <section>
            <div className='border-bottom'>
                <div className='d-flex  justify-content-end py-3'>
                    <Button className='m-0' variant='outlined' onClick={() => { setShow(!show) }}>Ask A Questions</Button>
                </div>
                {show && <div className='col-12'>
                    <Row className='mb-3'>
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label htmlFor="inputPassword5">NAME *</Form.Label>
                            <Form.Control
                                type="password"
                                id="inputPassword5"
                                aria-describedby="passwordHelpBlock"
                                className='rounded-0 mb-3'
                                placeholder='Your Name'
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label htmlFor="inputPassword5">EMAIL (OPTIONAL)</Form.Label>
                            <Form.Control
                                type="password"
                                id="inputPassword5"
                                aria-describedby="passwordHelpBlock"
                                className='rounded-0 mb-3'
                                placeholder='Your Email'
                            />

                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="Enter Your Questions"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" placeholder="Leave a comment here" />
                            </FloatingLabel>

                        </Form.Group>
                        <div className=''>
                            <Button variant='contained' size='small'>Send Questions</Button>
                        </div>
                    </Row>
                </div>
                }
            </div>


            <div className='row'>
                <div className='col-12'>

                    {/* ask Questions start */}
                    <div className='d-flex justify-content-between text-muted'>
                        <div>
                            <span><b>Amit</b></span>
                        </div>
                        <div>
                            <span>05/02/2003</span>
                        </div>
                    </div>
                    <div className='border-bottom py-1'>
                        <ol className='mb-1'>
                            <li><strong>Q:Can you ship to maharastra ?</strong></li>
                        </ol>
                        <p><span><BiSolidMessageRounded /></span>Answers <span>(0)</span></p>
                    </div>
                    {/* ask Questions end */}
                    {/* ask Questions start */}
                    <div className='d-flex justify-content-between text-muted'>
                        <div>
                            <span><b>Rahul</b></span>
                        </div>
                        <div>
                            <span>05/02/2003</span>
                        </div>
                    </div>
                    <div className='border-bottom py-1'>
                        <ol className='mb-1'>
                            <li><strong>Q:What is the frequency for channel 1?</strong></li>
                        </ol>
                        <p><span><BiSolidMessageRounded /></span>Answers <span>(0)</span></p>
                    </div>
                    {/* ask Questions end */}
                    {/* ask Questions start */}
                    <div className='d-flex justify-content-between text-muted'>
                        <div>
                            <span><b>Farhan Qureshi</b></span>
                        </div>
                        <div>
                            <span>05/02/2003</span>
                        </div>
                    </div>
                    <div className='border-bottom py-1'>
                        <ol className='mb-1'>
                            <li><strong>Q:Can you ship to dubai ?</strong></li>
                        </ol>
                        <p><span><BiSolidMessageRounded /></span>Answers <span>(0)</span></p>
                    </div>
                    {/* ask Questions end */}
                    {/* ask Questions start */}
                    <div className='d-flex justify-content-between text-muted'>
                        <div>
                            <span><b>Amit</b></span>
                        </div>
                        <div>
                            <span>05/02/2003</span>
                        </div>
                    </div>
                    <div className='border-bottom py-1'>
                        <ol className='mb-1'>
                            <li><strong>Q:Can you ship to maharastra ?</strong></li>
                        </ol>
                        <p><span><BiSolidMessageRounded /></span>Answers <span>(0)</span></p>
                    </div>
                    {/* ask Questions end */}
                    {/* ask Questions start */}
                    <div className='d-flex justify-content-between text-muted'>
                        <div>
                            <span><b>Amit</b></span>
                        </div>
                        <div>
                            <span>05/02/2003</span>
                        </div>
                    </div>
                    <div className='border-bottom py-1'>
                        <ol className='mb-1'>
                            <li><strong>Q:Can you ship to maharastra ?</strong></li>
                        </ol>
                        <p><span><BiSolidMessageRounded /></span>Answers <span>(0)</span></p>
                    </div>
                    {/* ask Questions end */}
                    {/* ask Questions start */}
                    <div className='d-flex justify-content-between text-muted'>
                        <div>
                            <span><b>Amit</b></span>
                        </div>
                        <div>
                            <span>05/02/2003</span>
                        </div>
                    </div>
                    <div className='border-bottom py-1'>
                        <ol className='mb-1'>
                            <li><strong>Q:Can you ship to maharastra ?</strong></li>
                        </ol>
                        <p><span><BiSolidMessageRounded /></span>Answers <span>(0)</span></p>
                    </div>
                    {/* ask Questions end */}

                </div>
            </div>
        </section>
    )
}

export default ReviewQuestions
