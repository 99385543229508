import React, { useState } from 'react'
import '../registered/Registered.css'
import './Login.css'

import { CiLock, CiMail, CiUser } from 'react-icons/ci';
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import axios from 'axios';
import { base_url } from '../../server';

function Login() {
    const [loginData, setLoginData] = useState(
        { email: "", password: "", }
      )
      const [errorMessage, setErrormessage] = useState(false)
      const navigate  = useNavigate()
      
      const handleSubmit = async () => {
        try {
          await axios.post(`${base_url}user/register`,loginData)
          setErrormessage(false)
          if (loginData) {
            navigate("/home")
          }

        } catch (error) {
          setErrormessage(error.message)
        }
      }
      const handleChange = (e) => {
        const val = e.target.value
        const name = e.target.name;
        const cloneobj = { ...loginData }
        cloneobj[name] = val
        setLoginData(cloneobj)
      }
    return (
        <section className='loginBg my-5 border'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 m-auto  col-12'>
                        <div className='p-5'>
                            <h2 className='text-center ' id='text-login-color1'>Sign Into Your Account</h2>
                            <div class="login-inner-form">
                                <form action="#" method="GET">
                                    <div class="form-group form-box">
                                        <input name="email" type="email" class="form-control" placeholder="Email Address" aria-label="Email Address" onChange={handleChange} required/>
                                        <CiMail />
                                    </div>
                                    <div class="form-group form-box clearfix">
                                        <input name="password" type="password" class="form-control" autocomplete="off" placeholder="Password" aria-label="Password" onChange={handleChange} required/>
                                        <CiLock />
                                    </div>
                                    <div class="form-group checkbox clearfix">
                                        <div class="clearfix float-start d-flex justify-content-between w-100">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="rememberme" />
                                                <label class="form-check-label" for="rememberme" id='text-login-color1'>
                                                    Remember me
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label" for="rememberme" id='text-login-color1'>
                                                Forgot your password?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {errorMessage && <Alert className='mb-3' severity="error">Somthing went wrong {errorMessage}</Alert>}
                                    <div class="form-group">
                                        <button type="button" class="btn btn-primarys btn-lg btn-theme" onClick={handleSubmit}>LOGIN</button>
                                    </div>
                                </form>
                            </div>
                            <p>Don't have an account?<Link to="/register" class="thembo"> Register here</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login
