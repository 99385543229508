import React from 'react'
import galleryImg from '../../../../../assets/img/galleryImage/detailsGalleryimage.jpg'
function DetailsGallery() {
  return (
    <div className='w-75 m-auto'>
      <div className='row'>
        <div className='col-md-12 col-lg-12 col-12'>
            <picture>
                <img src={galleryImg} alt='galleryImage' width={100+'%'}/>
            </picture>
        </div>
        
      </div>
    </div>
  )
}

export default DetailsGallery
