import React, { useEffect, useState } from 'react'
// mui import start 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// mui import End 
// image Import 
import cardvisa from '../../assets/img/networkBg/cardvisa.png'
import PoweredBy from '../powereBy/PoweredBy';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../../server';
import { Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
function Footer() {
    const [state, setState] = useState(null)
    const [categState, setcategState] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const {t} = useTranslation()
    const getFooterData = async () => {
        try {
            const response = await axios.get(`${base_url}adminWeb_footer/public`, { withCredentials: true })
            setState(response.data)
        } catch (error) {
            setErrorMessage(error.message)
        }
    }
    const getCategories = async () => {
        try {
            const response = await axios.get(`${base_url}/category/public`, { withCredentials: true })
            setcategState(response.data)
        } catch (error) {
            setErrorMessage(error.message)
        }
    }
    useEffect(() => {
        getCategories()
        getFooterData()
    }, [])
    return (
        <>
            <section className='bg-dark text-light'>
                {errorMessage && <Alert variant="filled" severity="error" className='m-5'>
                    This is an error alert — check it out! &nbsp;&nbsp;&nbsp; {errorMessage}
                </Alert>}
                {!errorMessage &&
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 col-lg-3 col-12'>
                                <div className=''>
                                    <List
                                        sx={{ width: '100%', }}
                                        aria-label="contacts"
                                    >
                                        <ListItem className='p-0'>
                                            <ListItemButton>
                                                <ListItemText inset primary={<Link to='/about-us' className='text-decoration-none text-light'>{t('about')}</Link>} sx={{ fontWeight: "800" }} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem className='p-0'>
                                            <ListItemButton>
                                                <ListItemText inset primary={<Link to='/blogs' className='text-decoration-none text-light'>{t('blog')}</Link>} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem className='p-0'>
                                            <ListItemButton>
                                                <ListItemText inset primary={<Link to='/faqs' className='text-decoration-none text-light'>{t('faqs')}</Link>} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem className='p-0'>
                                            <ListItemButton>
                                                <ListItemText inset primary={<Link to='/privacy-policy' className='text-decoration-none text-light'>{t('privacyPolicy')}</Link>} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem className='p-0'>
                                            <ListItemButton>
                                                <ListItemText inset primary={<Link to='/term-condition' className='text-decoration-none text-light'>{t('termCondition')}</Link>} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </div>
                            </div>
                            <div className='col-md-3 col-lg-3 col-12'>
                                <div className=''>

                                    <List
                                        sx={{ width: '100%', }}
                                        aria-label="contacts"
                                    >
                                        <ListItem className='p-0'>
                                            <ListItemButton>
                                                <ListItemText inset primary={t('allCategories')} sx={{ fontWeight: "800" }} />
                                            </ListItemButton>
                                        </ListItem>
                                        {categState?.slice(1, 5).map((item) => {
                                            return <ListItem className='p-0'>
                                                <ListItemButton>
                                                    <ListItemText inset primary={<Link to={`/category/${item?.uid}/${item?.slug}`} className='text-decoration-none text-light'>{item?.name}</Link>} />
                                                </ListItemButton>
                                            </ListItem>
                                        })}
                                        
                                    </List>
                                </div>
                            </div>
                            <div className='col-md-3 col-lg-3 col-12'>
                                <div className=''>
                                    <List
                                        sx={{ width: '100%', }}
                                        aria-label="contacts"
                                    >
                                        <ListItem className='p-0'>
                                            <ListItemButton>
                                                <ListItemText inset primary={t('coopration')} sx={{ fontWeight: "800" }} />
                                            </ListItemButton>
                                        </ListItem>
                                        {categState?.slice(5, 10).map((item) => {
                                            return <ListItem className='p-0'>
                                                <ListItemButton>
                                                    <ListItemText inset primary={<Link to={`/category/${item?.uid}/${item?.slug}`} className='text-decoration-none text-light'>{item?.name}</Link>} />
                                                </ListItemButton>
                                            </ListItem>
                                        })}
                                    </List>
                                </div>
                            </div>
                            <div className='col-md-3 col-lg-3 col-12'>
                                <div className=''>
                                    <List
                                        sx={{ width: '100%', }}
                                        aria-label="contacts"
                                    >
                                        <ListItem className='p-0'>
                                            <ListItemButton >
                                                <ListItemText className='p-0 text-light' inset primary={t('address')} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem className='p-0'>
                                            <ListItemButton >
                                                <ListItemText className='p-0 text-light' inset primary={state?.office_address} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem className='p-0'>
                                            <ListItemButton >
                                                <ListItemText className='p-0 text-light' inset primary={state?.location} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem className='p-0'>
                                            <ListItemButton >
                                                <ListItemText className='p-0' inset primary={state?.email} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem className='p-0'>
                                            <ListItemButton >
                                                <ListItemText className='p-0' inset primary={state?.phoneNo} />
                                            </ListItemButton>
                                        </ListItem>


                                    </List>
                                </div>
                            </div>
                        </div>
                    </div>}

            </section>
            <PoweredBy />
        </>
    )
}

export default Footer
