import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
function Ratings({ratSku}) {
    const [value, setValue] = useState  (3.0);
    const getSku = ratSku?.prices[0]
    return (
        <Box
            sx={{
                '& > legend': { mt: 2 },
            }}
        >
            
            <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            />
            <div className='rating' style={{fontSize:"13px",fontWeight:'600'}}>3.7 (46 reviews)</div>
            {getSku && 
            <div className='rating' style={{fontSize:"13px",fontWeight:'600'}}>SKU : {getSku.sku}</div>}
            

        </Box>
    )
}
export default Ratings
