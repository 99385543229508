import React from 'react'
import { Link } from 'react-router-dom'

function PoweredBy() {
  return (
    <div className='powered text-center  text-light' style={{backgroundColor:'#86b7fe',fontSize:'14px'}}>
      <span className=' m-auto'> 
      Copyright ©2023  Powered By &nbsp;
        <Link to={'https://www.abarissoftech.com/'} target='blank' className='text-decoration-none text-light'>Abaris Softech</Link>
      </span>
    </div>
  )
}

export default PoweredBy
