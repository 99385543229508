import React from 'react'
import ProductsCrums from './productscrums/ProductsCrums'
import NewArrivalsProducts from './newArrivelsProducts/NewArrivalsProducts'

function NewArrivels() {
  const breadCrumsTittle  = 'NEW ARRIVAL'
  return (
    <section>
      <ProductsCrums data = {breadCrumsTittle}/>
      <NewArrivalsProducts/>
    </section>
  )
}

export default NewArrivels
