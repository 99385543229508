import React from 'react'
import togetherPro1 from '../../../../assets/img/boughtTogether/BF0C.jpg'
import togetherPro2 from '../../../../assets/img/boughtTogether/BF0C1.jpg'
import togetherPro3 from '../../../../assets/img/boughtTogether/BF0C2.jpg'
import { BiPlusMedical } from 'react-icons/bi'
import { Button, Checkbox } from '@mui/material'
import { Label } from '@mui/icons-material'
import DetailsAndReview from '../../productsContentDetails/detailsAndReview/DetailsAndReview'
function ProductsBoughtTogether() {
    return (
        <>
            <h3>FREQUENTLY BOUGHT TOGETHER
            </h3>
            <h6 className='text-muted'>Save money buying these products together
            </h6>
            <div className='row'>

                <div className='row'>
                    <div className='col-md-6 col-lg-6'>
                        <div className='row'>
                            <div className='col-md-3 col-lg-3 col-3'>
                                <figure className='border'>
                                    <img src={togetherPro1} alt='addProducts' width={100 + '%'} />
                                </figure>
                                {/* <span className='' style={{ width: "fit-content" }}>
                                    <BiPlusMedical />
                                </span> */}
                            </div>

                            <div className='col-md-3 col-lg-3 col-3'>
                                <figure className='border'>
                                    <img src={togetherPro2} alt='addProducts' width={100 + '%'} />
                                </figure>
                                {/* <span style={{ width: "fit-content" }}>
                                    <BiPlusMedical />
                                </span> */}
                            </div>

                            <div className='col-md-3 col-lg-3 col-3'>
                                <figure className='border'>
                                    <img src={togetherPro3} alt='addProducts' width={100 + '%'} />
                                </figure>
                            </div>
                            <div className='col-md-3 col-lg-3 col-3'>
                                <span>Total  : <symbol>AED {''} </symbol><b>765423</b></span>
                                <div className='p-1'>
                                    <Button variant="contained" size="small">
                                        ADD TO CART
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row py-3'>
                <div className='col-md-12 col-lg-12 col-12'>
                    <div className='d-flex'>
                        <Checkbox {...Label} defaultChecked className='p-0' />
                        <p className='m-0'><b>THIS ITEM: BF-888S [6 PACKS + SIX-WAY CHARGER + PROGRAMMING CABLE] </b><span><b>₹79.99 AED</b></span >&nbsp;<span className=''>₹99.99 AED</span></p>
                    </div>
                    <div className='d-flex'>
                        <Checkbox {...Label} defaultChecked className='p-0' />
                        <p className='m-0'>BF-888S [2 PACK] 5W UHF RADIOUS / 2PCS &nbsp;<span><b>₹79.99 AED</b></span > &nbsp;<span className=''>₹99.99 AED</span></p>
                    </div>
                    <div className='d-flex'>
                        <Checkbox {...Label} defaultChecked className='p-0' />
                        <p className='m-0'>K-PLUG PROGRAMMING CABLE <span><b>₹79.99 AED</b></span ></p>
                    </div>
                </div>
            </div>
            <div className='row py-3'>
                <div className='col-md-12 col-lg-12 col-12'>
                    <div className='d-flex'>
                        <DetailsAndReview/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductsBoughtTogether
