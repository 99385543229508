import React from 'react'


import BannerWrpper from './bannerWrapper/BannerWrpper'
import SeriesProducts from './seriesProducts/SeriesProducts'
import NewArrivels from './newArrivals/NewArrivels'
import BestSelling from './bestSelling/BestSelling'
import Blogs from './blogsInHome/Blogs'
import Network from './networks/Network'
import LokingForTwo from './lookingForTwo/LokingForTwo'
import IntroducingLinkWare from './introducingLink/IntroducingLinkWare'
import NeedsAnExperts from './needExperts/NeedsAnExperts'

function Homes() {
  
  return (
    <>
      <BannerWrpper/>  
      <IntroducingLinkWare/> 
      <SeriesProducts/>
      <LokingForTwo/>
      <NewArrivels/>
      <BestSelling/>
      <NeedsAnExperts/>
      <Blogs/>
      <Network/>
      
    </>
  )
}

export default Homes