import { Tab, TabPanel, Tabs, TabsList } from '@mui/base'
import Reviews from './reviews/Reviews'
import ReviewQuestions from './reviewQuestions/ReviewQuestions'


function CustomerReview() {
    
    return (
        <div className='container'>
            <div className='border p-5'>
                <div className='border-bottom'>
                    <h2>Customer Reviews</h2>
                </div>
                <div className=' d-flex align-items-center justify-content-between'>
                    <Tabs className="w-100" defaultValue={0}>
                        <div className='border-bottom d-flex align-items-center justify-content-between'>
                            <TabsList className="py-3">
                                <Tab className="border-0 bg-white" value={0}><h5 className='text-decoration-underline'>Reviews (3532)</h5></Tab>
                                &nbsp;||&nbsp;
                                <Tab className="border-0 bg-white" value={1}><h5>Questions (7)</h5></Tab>
                            </TabsList>
                            <div>
                                <span className='text-muted'>
                                    1 To 10 From 1332
                                </span>
                            </div>
                        </div>

                        <TabPanel value={0} className="my-4">
                            <div className='row'>
                                <Reviews/>
                            </div>
                        </TabPanel>
                        <TabPanel value={1} className="">
                            <ReviewQuestions/>
                        </TabPanel>
                    </Tabs>
                    {/* <div className='d-flex py-3'>
                            
                            
                            
                        </div> */}

                </div>
            </div>
        </div>
    )
}

export default CustomerReview
