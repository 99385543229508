import React from 'react'
import './FaqWrapper.css'
function FaqWrapper() {
  return (
    <section className='bgImage'>
    </section>
  )
}

export default FaqWrapper
