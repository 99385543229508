import React from 'react'
import Homes from '../../components/home/Homes'

function HomesPages() {
  return (
    <div>
      <Homes/>
    </div>
  )
}

export default HomesPages
