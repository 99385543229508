import React, { useEffect } from 'react'
import WrapperPrivacy from './wrapperPrivacy/WrapperPrivacy'
import Breadcrums from '../../common/breadcrums/Breadcrums'
import PrivacyPolicyDeatail from './privacyPolicyDetails/PrivacyPolicyDeatail'

function PrivacyPolicy() {
    const breadcrumsTitle = {
        id: '1', title: 'PRIVACY POLICY'
    }
    useEffect(()=>{
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    })
    return (
        <>
            <Breadcrums breadcrumsTitle={breadcrumsTitle} />
            <WrapperPrivacy />
            <PrivacyPolicyDeatail />
        </>
    )
}

export default PrivacyPolicy
