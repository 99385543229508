import React, { useEffect, useState } from 'react'
import FaqWrapper from './faqWrapper/FaqWrapper'
import Breadcrums from '../../common/breadcrums/Breadcrums'


//react-bootstarp import 
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FaqAcordians from './faqAcordians/FaqAcordians';
import './Faq.css'
import axios from 'axios';
import { base_url } from '../../server';

function Faq() {
    const [faqCategory,setFaqCategory] = useState(null)
    const breadcrumsTitle = {
        id: '1', title: 'FREQUENTLY ASKED QUESTIONS'
    }
    const getFaqData = async()=>{
        const response =await axios.get(`${base_url}faqsMaster`)
        setFaqCategory(response.data);
    }

    useEffect(() => {
        getFaqData()
        window.scrollTo(
            {
                top: 0,
                behavior: 'smooth'
            }
        )
    }, [])
    return (
        <>
            <div className='my-4'>
                <Breadcrums breadcrumsTitle={breadcrumsTitle} />
            </div>
            <FaqWrapper />
            <section>
                <div className='container'>
                    <div className='w-75 m-auto'>
                        <div className='row'>
                            <div className='col-12'>
                                <h4 className='text-center' style={{ letterSpacing: '3px' }}>Frequently Asked Question</h4>
                                <p className='text-danger text-center'>FTH Simplified Solutions is one of the most trusted and best E-commerce platforms. With over 50 crore customers across 20000 + ,   FTH UAE is trusted by more than 500000 sellers online throughout.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='m-auto'>
                            <Tabs
                                defaultActiveKey="tab1"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                {faqCategory?.map((faqItem,i)=>{
                                    console.log(faqItem);
                                    return <Tab eventKey={`tab${i}`} title={faqItem?.title}>
                                    <FaqAcordians />
                                </Tab>
                                })}
                            </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default Faq
