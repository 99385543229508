import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import './LokingForTwo.css'
import jtecimg from '../../../assets/img/products/SmartCall Alert Social - Square.gif'
import jtecimg0 from '../../../assets/img/products/Extreme - Dog1200x1200.webp'
import { useTranslation } from 'react-i18next'
function LokingForTwo() {
    const {t} = useTranslation()
    return (
        <section className=''>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-12'>
                        <div className='text-center link-ware  p-3'>
                            <h2>{t('looking')}</h2>
                            <h4>
                                <em>{t('several')}</em>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid bg-tablet'>
                <div className='row'>

                    <div className='col-md-6 col-lg-6 col-12'>
                        <div className='m-auto smartPara h-100' style={{ width: '65%' }}>
                            <div className='d-flex align-items-center h-100'>
                                <div className=''>
                                    <h3>{t('smartcallAllert')}</h3>
                                    <p>{t('latestIndustry')} <span><b>{t('smartPager')}</b></span></p>
                                    <h5>
                                        <Button variant='contained'>{t('shopnow')}</Button>
                                    </h5>
                                    <picture>
                                        <img className='rounded' src={jtecimg} alt='' />
                                    </picture>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <div className='m-auto smartPara h-100' style={{ width: '65%' }}>
                            <div className='d-flex align-items-center h-100'>
                                <div className='my-3'>
                                    <picture>
                                        <img className='rounded' src={jtecimg0} alt='' width={100 + '%'}/>
                                    </picture>
                                    <h3>{t('extrme')}</h3>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p> */}
                                    <p>{t('contrary')}  <span><b>{t('smartPager')}</b></span></p>
                                    <h5>
                                        <Button variant='contained'>{t('learnMore')}</Button>
                                    </h5>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LokingForTwo
