import React from 'react'
import { Layout, theme } from 'antd';
import { Select, Space } from 'antd';
const { Header } = Layout;
function ProductCategoryHeader() {
    const handleChange = (value) => {
        console.log(`selected ${value}`);
      };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Header style={{ padding: 0, background: colorBgContainer,}} className='d-flex justify-content-end w-100' >
            <div>
                <span>Sort by:</span>
                &nbsp;
                &nbsp;
                &nbsp;
                <Select
                    defaultValue="Featured"
                    style={{ width: 200,borderRadius:'0' }}
                    onChange={handleChange}
                    options={[
                        { value: 'Featured', label: 'Featured' },
                        { value: 'Price, low to high', label: 'Price, low to high' },
                        { value: 'Alphabetically, A-Z', label: 'Alphabetically, A-Z' },
                        { value: 'Alphabetically, Z-A', label: 'Date, old to new' },
                        { value: 'Alphabetically, Z-A', label: 'Date, new to old' },
                        { value: 'Alphabetically, Z-A', label: 'Best Selling' },
                    ]}
                />
            </div>
        </Header>
    )
}

export default ProductCategoryHeader

