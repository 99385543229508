import React from 'react'
import './NeedsAnExperts.css'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
function NeedsAnExperts() {
    const { t } = useTranslation()
    return (
        <section className='bgNeedExp'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6-w-50 m-auto col-md-6 col-12'>
                        <div className=' py-4'>
                            <h2>
                                <strong>{t('great')}</strong>
                            </h2>
                            <p>
                                <b>
                                    {t('contrary')}
                                </b>
                            </p>
                            <Button variant='contained'>{t('requestQoute')}</Button>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <div className='needExpBg'></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NeedsAnExperts
