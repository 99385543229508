import React, { useEffect, useState } from 'react'
import Breadcrums from '../../common/breadcrums/Breadcrums'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { base_url } from '../../server'
import { Alert } from '@mui/material'
function Blogs() {
    const [state, setState] = useState(null)
    const [errorMessage, seterrorMessage] = useState(false)
    const getBlogData = async () => {
        try {
            const res = await axios.get(`${base_url}blogs`, { withCredentials: true })
            setState(res.data);
            seterrorMessage(false)
        } catch (error) {
            seterrorMessage(error.message)
        }
    }
    const breadcrumsTitle = {
        id: '1', title: 'NEWS'
    }
    useEffect(() => {
        getBlogData()
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    return (
        <section>
            <div className='container'>
                <div className=''>
                    <Breadcrums breadcrumsTitle={breadcrumsTitle} />
                </div>
                {errorMessage && <Alert variant="filled" severity="error" className='m-5'>
                    This is an error alert — check it out! &nbsp;&nbsp;&nbsp; {errorMessage}
                </Alert>}
                {state && state?.map((item, i) => {
                    return <div className='row py-3' key={i}>
                        <div className='col-md-4 col-lg-3 col-12'>
                            <div className=''>
                                <picture>
                                    <img src={item?.banner?.url} alt='blogsImg' width={100 + '%'} className='rounded' />
                                </picture>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-5 col-12'>
                            <h4>{item?.title}</h4>
                            <p>{item?.short_description}</p>
                            <Link className='text-dark' to={`/blog-details/${item.uid}/${item._id}`}>Learn More</Link>
                        </div>
                        <div className='col-md-3 col-lg-4 col-12'>
                            <div className='border-bottom'>
                                <h5 className='text-decoration-underline'>RECENT POSTS</h5>
                            </div>
                            <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                                <span>
                                    <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                                </span>
                            </div>
                            {/* <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                                <span>
                                    <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                                </span>
                            </div>
                            <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                                <span>
                                    <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                                </span>
                            </div>
                            <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                                <span>
                                    <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                                </span>
                            </div> */}
                        </div>
                    </div>
                })}

                {/* <div className='row py-3'>
                    <div className='col-md-4 col-lg-3 col-12'>
                        <div className=''>
                            <picture>
                                <img src={blog2} alt='blogsImg' width={100 + '%'} className='rounded' />
                            </picture>
                        </div>
                    </div>
                    <div className='col-md-5 col-lg-5 col-12'>
                        <h4>How To Customize Power-On Picture For UV-17R?</h4>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <Link className='text-dark'>Learn More</Link>
                    </div>
                    <div className='col-md-3 col-lg-4 col-12'>
                        <div className='border-bottom'>
                            <h5 className='text-decoration-underline'>RECENT POSTS</h5>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row py-3'>
                    <div className='col-md-4 col-lg-3 col-12'>
                        <div className=''>
                            <picture>
                                <img src={blog3} alt='blogsImg' width={100 + '%'} className='rounded' />
                            </picture>
                        </div>
                    </div>
                    <div className='col-md-5 col-lg-5 col-12'>
                        <h4>How To Customize Power-On Picture For UV-17R?</h4>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <Link className='text-dark'>Learn More</Link>
                    </div>
                    <div className='col-md-3 col-lg-4 col-12'>
                        <div className='border-bottom'>
                            <h5 className='text-decoration-underline'>RECENT POSTS</h5>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                        <div className='py-3' style={{ borderBottom: "dashed 1px  #0000ff7a" }}>
                            <span>
                                <Link className='text-decoration-none text-muted'>How to Customize Power-on Picture for UV-17R?</Link>
                            </span>
                        </div>
                    </div>
                </div> */}

            </div>
        </section>
    )
}

export default Blogs
