import React from 'react'
import MultiProductsItems from '../multiProductsItems/MultiProductsItems'
import ProductsContentDetails from '../productsContentDetails/ProductsContentDetails'

function ProductItem({productDetails}) {
  return (
    <div className='row h-100 py-5 gx-5'>
      <MultiProductsItems productDetails={productDetails}/>
      <ProductsContentDetails productDetails={productDetails}/>
    </div>
  )
}

export default ProductItem
