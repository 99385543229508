import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import { FloatingLabel } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
// antd

import "react-widgets/styles.css";
import location from '../../../assets/img/networkBg/gps.png'
import mail from '../../../assets/img/networkBg/mail.png'
import phone from '../../../assets/img/networkBg/iphone.png'
import './ContactsForn.css'
import { base_url } from '../../../server';
import axios from 'axios';
import { Select, Space } from 'antd';
import { Multiselect } from 'react-widgets';
import { useTranslation } from 'react-i18next';
function ContactsForn() {
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        category: '',
        subCategory: '',
        product: ''
    })
    // const [finalCatD, setFinalCatD] = useState();
    const {t} = useTranslation()
    const [categ, setCateg] = useState(null)
    const handleSubmit = async () => {
        // console.log(contactData);
        try {
            const res = await axios.post(`${base_url}contactMessage/add_ContactMessage`, contactData)
        } catch (error) {
            alert(error.message)
        }

    }
    const getCategoryData = async () => {
        try {
            const response = await axios.get(`${base_url}category/public`, { withCredentials: true })
            setCateg(response.data)
        } catch (error) {
            alert(error.message)
        }

    }
    const handleInput = (e) => {
            const val = e.target.value
            console.log(val);
            const name = e.target.name;
            const cloneobj = { ...contactData }
            cloneobj[name] = val
            setContactData(cloneobj)
    }
    useEffect(() => {
        handleSubmit()
        getCategoryData()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])
    return (
        <div className='row '>
            <div className='col-md-12 col-sm-12 col-lg-12 col-12'>
                <div className='text-center p-3 m-2' style={{ fontFamily: 'Anton', letterSpacing: "5px", }}>
                    <h3>{t('welcome')}</h3>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 col-12 '>
                <div className='text-uppercase py-3 my-2' style={{ fontFamily: 'Anton', letterSpacing: "2px", }}>
                    <h3>{t('find')}</h3>
                </div>
                <div className='mx-2'>
                    <div className='row  p-2 rounded ' >
                        <div className='col-md-2 col-lg-2 col-2'>
                            <div className='fontSizeContact  p-1 border rounded'>
                                <img src={location} alt='location' className='w-100' />
                            </div>
                        </div>
                        <div className='col-md-10 col-lg-10 col-10'>
                            <h4><b>{t('address')}</b></h4>
                            <div className=''>
                                <span>{t('addressfthElec')}</span>
                            </div>
                        </div>
                        <div className='col-md-2 col-lg-2 col-2'>
                            <div className='fontSizeContact  p-1 border rounded'>
                                <img src={phone} alt='location' className='w-100' />
                            </div>
                        </div>
                        <div className='col-md-10 col-lg-10 col-10'>
                            <h4><b>{t('contact')}</b></h4>
                            <div className=''>
                                <div className=''>
                                    <span><b>{t('tel')}:</b> 00971 4 8877738</span>
                                </div>
                                <div className=''>
                                    <span><b>{t('mob')}:</b> 00971 50213 7884</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 col-lg-2 col-2'>
                            <div className='fontSizeContact  p-1 border rounded'>
                                <img src={mail} alt='location' className='w-100' />
                            </div>
                        </div>
                        <div className='col-md-10 col-lg-10 col-10'>
                            <h4><b>{t('email')}</b></h4>
                            <div className=''>
                                <div className=''>
                                    <span><b>{t('email')}:</b> sales@fthuae.com</span>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <div className='col-md-7 col-lg-8 col-12'>
                <div className='text-uppercase py-3 my-2' style={{ fontFamily: 'Anton', letterSpacing: "2px", }}>
                    <h3>{t('welcome')}</h3>
                </div>
                <div className='row'>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <Form.Label htmlFor="inputPassword5"><b>{t('name')} </b></Form.Label>
                        <Form.Control
                            type="Text"
                            aria-describedby="passwordHelpBlock"
                            className='rounded-0 mb-3'
                            onChange={handleInput}
                            name='name'
                        />
                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <Form.Label htmlFor="inputPassword5"><b>{t('email')} </b></Form.Label>
                        <Form.Control
                            type="Email"
                            aria-describedby="passwordHelpBlock"
                            className='rounded-0 mb-3'
                            onChange={handleInput}
                            name='email'
                        />
                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <Form.Label htmlFor="inputPassword5"><b>{t('phone')} </b></Form.Label>
                        <Form.Control
                            type="number"
                            aria-describedby="passwordHelpBlock"
                            className='rounded-0 mb-3'
                            onChange={handleInput}
                            name='phone'
                        />

                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <Form.Label htmlFor="inputPassword5"><b>{t('subjects')} </b></Form.Label>
                        <Form.Control
                            type="text"
                            aria-describedby="passwordHelpBlock"
                            className='rounded-0 mb-3'
                            onChange={handleInput}
                            name='subject'
                        />
                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <Form.Label htmlFor="inputPassword5"><b>{t('products')}</b></Form.Label>
                        <Form.Control
                            type="text"
                            aria-describedby="passwordHelpBlock"
                            className='rounded-0 mb-3'
                            onChange={handleInput}
                            name='product'
                        />

                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <Form.Label htmlFor="inputPassword5"><b>{t('category')}</b></Form.Label>
                        {/* <Multiselect
                            isObject={true}
                            displayValue="name"
                            
                            options={categ}
                            showCheckbox
                            selectedValues={[]}
                            onRemove={(selectedCat) => {
                                const selectedIds = selectedCat.map((cat) => {
                                    return cat
                                })
                                setFinalCatD(selectedIds)
                            }}
                            onSelect={(selectedCat) => {
                                const selectedIds = selectedCat.map((cat) => {
                                    return cat
                                })
                                setFinalCatD(selectedIds)
                            }}
                        /> */}
                        <Multiselect
                            dataKey="id"
                            textField="color"
                            defaultValue={[]}
                            data={categ?.map((item) => {
                                return item.name
                            })}
                        />
                        {/* <select class="form-select" aria-label="Default select example" name='category' onChange={handleInput}>
                            {categ?.map((item, i) => {
                                return <option selected key={i} value={item?.uid}>{item?.name}</option>
                            })}
                        </select> */}
                    </div>

                </div>

                <Form.Label htmlFor="inputPassword5"><b>{t('messages')} </b></Form.Label>
                <FloatingLabel
                    controlId="floatingTextarea"
                    label={t('messages')}
                    className='rounded-0 mb-3'

                >
                    <Form.Control as="textarea" onChange={handleInput}
                        name={t('messages')} placeholder="" style={{ height: '85px' }} />
                </FloatingLabel>

            </div>
            <div className='col-md-12 col-sm-12 col-lg-12 col-12'>
                <div className='text-center p-3 m-2' style={{ fontFamily: 'Anton', letterSpacing: "5px", }}>
                    <Button variant="contained" onClick={() => handleSubmit()} className='rounded-0 w-50'>{t('submit')}</Button>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 col-lg-12 col-12'>
                    <div className='locationMap m-auto '>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.9846284007413!2d55.378065375116925!3d25.271102528703796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d561640ddd3%3A0xb17469a67640e081!2sFTH%20Electronics%20L.L.C!5e0!3m2!1sen!2sin!4v1700456663403!5m2!1sen!2sin" width="100%" height="450" style={{ border: 'solid 5px #0627cb30', borderRadius: '5px', padding: '30px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContactsForn
