import React from 'react'
import Banner from '../../common/banner/Banner'
import ContactsForn from './contactsForm/ContactsForn'

function ContactUs() {
  const title = 'OEM REQUEST'
  return (
    <section className='bg-light'>
      <Banner title={title}/>
      <div className='container'>
        <ContactsForn/>
      </div>
    </section>
  )
}

export default ContactUs
