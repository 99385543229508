import React from 'react'
import Registered from '../../components/registered/Registered'

function Registeredpage() {
  return (
    <>
      <Registered/>
    </>
  )
}

export default Registeredpage
