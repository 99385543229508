import React, { useEffect, useState } from 'react'
import './Registered.css'
import { MdOutlineFacebook } from "react-icons/md";
import { FaGooglePlus } from 'react-icons/fa';
import { CiLock, CiMail, CiUser } from 'react-icons/ci';
import { Link, useNavigate } from 'react-router-dom';
import { base_url } from '../../server';
import axios from 'axios';
import { Alert } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
//   import 'react-toastify/dist/ReactToastify.css';
import logo from "../../assets/img/iradiosLogo-removebg.png"

// import { FaXTwitter } from "react-icons/fa6";
function Registered() {
  const [registerData, setRegisterData] = useState(
    { firstname: "", lastname: "", email: "", password: "", }
  )
  const [errorMessage, setErrormessage] = useState(false)

  const navigate = useNavigate()
  const notify = () => toast("Registered Successful!");
  const postregistereData = async () => {
    try {
      await axios.post(`${base_url}user/register`,registerData)
      setErrormessage(false)
      notify()
      if (registerData){
        setTimeout(() => {
          navigate("/login")
        }, 1000);
      }
    } catch (error) {
      setErrormessage(error.message)
    }
  }
  const handleChange = (e) => {
    const val = e.target.value
    const name = e.target.name;
    const cloneobj = { ...registerData }
    cloneobj[name] = val
    setRegisterData(cloneobj)
  }

 

  return (
    <section>
      <div className=''>
        <div className='row'>
          <div className='col-md-5 col-lg-5 col-12'>
            <div className='login-12 p-5'>
              <div className='text-center'>
                <div className='leftLogin'>
                  <div className='loginBrand'>
                    <img src={logo} alt='' width={30 + '%'} />
                  </div>
                  <div className='loginHText'>
                    <h2 style={{ fontFamily: "anton", letterSpacing: "3px", padding: '12px' }}>CREATE AN ACCOUNT</h2>
                  </div>
                </div>
              </div>
              <ul class="social-list">
                <li><a href="#" class="facebook-color"><MdOutlineFacebook /><span>Facebook</span></a></li>
                {/* <li><a href="#" class="twitter-color"><FaXTwitter /><span>Twitter</span></a></li> */}
                <li><a href="#" class="google-color"><FaGooglePlus /><span>Google</span></a></li>
              </ul>
            </div>
            <div class="extra-login clearfix">
              <div className='px-5'>
                <hr />
              </div>
            </div>
            <div className='px-3 login-12'>
              <div class="login-inner-form">
                <form action="#" method="GET">
                  <div class="form-group form-box">
                    <input name="firstname" type="text" class="form-control" placeholder="First Name*" aria-label="Full Name" onChange={handleChange} required />
                    <CiUser />
                  </div>
                  <div class="form-group form-box">
                    <input name="lastname" type="text" class="form-control" placeholder="Last Name*" aria-label="Full Name" onChange={handleChange} required/>
                    <CiUser />
                  </div>
                  <div class="form-group form-box">
                    <input name="email" type="email" class="form-control" placeholder="Email Address*" aria-label="Email Address" onChange={handleChange} required />
                    <CiMail />
                  </div>
                  <div class="form-group form-box clearfix">
                    <input name="password" type="password*" class="form-control" autocomplete="off" placeholder="Password" aria-label="Password" onChange={handleChange} />
                    <CiLock />
                  </div>
                  <div class="form-group checkbox clearfix">
                    <div class="clearfix float-start">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="rememberme" />
                        <label class="form-check-label" for="rememberme">
                          I agree to the terms of service
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  {errorMessage && <Alert className='mb-3' severity="error">Somthing went wrong{errorMessage}</Alert>}
                  <div class="form-group">
                    <button type="button" class="btn btn-primarys btn-lg btn-theme" onClick={postregistereData} >Register</button>
                  </div>
                  <ToastContainer />
                </form>
              </div>
              <p>Already a member? <Link to="/login" class="thembo"> Login here</Link></p>
            </div>
          </div>
          <div className='col-md-7 col-lg-7 col-12'>
            <div className='bgRegistered '>
              <div className='rightLogin login-12' style={{ minHeight: "100vh" }}>
                <div class="info">
                  <div class="animated-text">
                    <h2>Welcome <span>to <b className='text-danger'>FTH</b> Simplified Solutions</span></h2>
                  </div>
                  <p>FTH (FTH Electronics L.L.C Dubai) is U.A.E’s leading provider of “Restaurant Wireless paging System” and services across the region since 2005 specializing mainly in Restaurant on-site paging systems & wireless communication devices.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Registered
