import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
// material ui 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, Button, CardActionArea, CardActions } from '@mui/material';

import ProductsCrums from '../newArrivals/productscrums/ProductsCrums';
import axios from 'axios';
import { base_url } from '../../../server';
import { useTranslation } from 'react-i18next';

const BlogTite = 'BLOGS'
function Blogs() {
    const [state, setState] = useState(null)
    const [errorMessage, seterrorMessage] = useState(true)
    const {t} = useTranslation()
    const getBlogsData = async () => {
        try {
            const res = await axios.get(`${base_url}blogs`, { withCredentials: true })
            setState(res.data)
            seterrorMessage(false)
        } catch (error) {
            seterrorMessage(error.messsage)
        }
    }
    useEffect(() => {
        getBlogsData()
    }, [])
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section>
            <div className='container-fluid'>
                <div className='border m-3 p-5'>
                    <div className='mb-3'>
                        <ProductsCrums data={BlogTite} />
                    </div>
                    {errorMessage && <Alert variant="filled" severity="error">
                        This is an error alert — check it out!{errorMessage}
                    </Alert>}
                    {!errorMessage &&
                        <Slider {...settings}>

                            {state?.map((item, i) => {
                                return <Card sx={{ maxWidth: 445 }} key={i}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={item?.banner?.url}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: "800" }} lg={{ padding: " 0 6px" }} style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                                                {item?.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{textOverflow:'ellipsis',height:'45px'}}>

                                                {item?.short_description?.length >200 ? item.short_description.slice(0,200)+"....": item.short_description }
                                                {/* {item?.short_description} */}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            {t('learnMore')}
                                        </Button>
                                    </CardActions>
                                </Card>
                            })}
                        </Slider>}
                </div>
            </div>
        </section>
    )
}

export default Blogs
