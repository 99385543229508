import React, { useEffect, useState } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import { CiUser } from "react-icons/ci"
import { BiChevronDown } from "react-icons/bi"
import { BsEnvelopeOpenFill } from "react-icons/bs"

import "../topHeader/TopHeader.css"
import { Link, useNavigate } from 'react-router-dom';

// react bootstarp ui  import 
import Form from 'react-bootstrap/Form';
// material ui  import 
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { Button } from '@mui/material';
// for Translation i18next
import i18next from 'i18next';
// for selector

import { base_url } from '../../server';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
function TopHeader() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [stateLang, setStateLang] = useState(null);
    const [defaultLang, setDefaultLang] = useState(null);
    const [errorMessage, setErrormessage] = useState(null);

    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const getLanguageId = async () => {

        try {
            const response = await axios.get(`${base_url}language`, { withCredentials: true })
            setStateLang(response.data)
        } catch (error) {
            setErrormessage(error.message)
        }
    }
    const defaLang = async () => {
        try {
            const defLanRes = await axios.get(`${base_url}settings/language`, { withCredentials: true })
            setDefaultLang(defLanRes.data.language)
        } catch (error) {
            setErrormessage(error.message)
        }
    }
    const getOnChangeValue = (e) => {
        console.log(e.target.value);
        if (e.target.value === '655346211265d086421c88ae') {
            i18n.changeLanguage('en')
            window.localStorage.setItem('prevLang', 'en')
            window.localStorage.setItem('defaultLang', e.target.value);
            setDefaultLang(e.target.value)
            changeLang(e.target.value)
        }
        else {
            i18n.changeLanguage('ar')
            window.localStorage.setItem('prevLang', 'ar')
            window.localStorage.setItem('defaultLang', e.target.value);
            setDefaultLang(e.target.value)
            changeLang(e.target.value)
        }
        // changeLang(e.target.value)
    }

    useEffect(() => {
        const preferredLanguage = window.localStorage.getItem('prevLang');
        if (preferredLanguage) {
            i18n.changeLanguage(preferredLanguage);
        }
    }, []);

    const changeLang = async (id) => {
        try {
            const response = await axios.put(`${base_url}settings/change/language`, { id: id }, { withCredentials: true })
            setDefaultLang(response.data)
            window.location.reload()
        } catch (error) {
            setErrormessage(error.message)
        }
    }


    const changeLanguage = (lang) => {
        i18next.changeLanguage(lang)
    }

    useEffect(() => {
        defaLang()
        setDefaultLang(window.localStorage.getItem('defaultLang'))
        getLanguageId()
    }, [])

    return (
        <Navbar bg="primary" className='p-0' >
            <div className='container d-block'>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex text-white searchBox'>
                        <div className='emailReq'>
                            <span className='p-2'>
                                <Link to='#' className='text-white'><BsEnvelopeOpenFill /></Link>
                            </span>
                            {/* <span className='p-2 emailReqCont'>
                                <Link to='#' className='text-white'>Subscribe to our Email Newsletter</Link>
                            </span> */}
                        </div>
                        <input placeholder='search' />
                    </div>
                    <div className='d-flex'>
                        <div className=' countItemTopCart'>
                            {errorMessage && <span className='px-2'>{errorMessage}</span>}
                            {!errorMessage && <Form.Select aria-label="Default select example" size="sm" value={defaultLang} onChange={getOnChangeValue} >
                                {stateLang?.map((item, i) => {
                                    return <option value={item?._id} key={i}>
                                        {item?.name}
                                    </option>
                                })}
                            </Form.Select>}
                        </div>
                        {/* <div className='countItemTopCart'>
                            <Form.Select aria-label="Default select example" size="sm" onChange={(e) => changeLanguage(e.target.value)} >
                                <option value={'en'}>ENGLISH</option>
                                <option value={'ar'}>ARABIC</option>
                            </Form.Select>
                        </div> */}
                        <div className='h-100 selectCountry'>
                            <Form.Select aria-label="Default select example" size="sm" value={'DUBAI'}  >
                                <option value={"Dubai"}>
                                    DUBAI
                                </option>
                                {/* <option value={"China"}>
                                    China
                                </option>
                                <option value={"Nepal"}>
                                    Nepal
                                </option> */}
                            </Form.Select>
                        </div>

                        <div>
                            <div className='loginbtn d-flex align-items-center cursor-pointer' id="fade-button"
                                // aria-controls={open ? 'fade-menu' : undefined}
                                // aria-haspopup="true"
                                // aria-expanded={open ? 'true' : undefined}
                                onClick={()=>navigate('/login')}
                            >
                                <span className='px-2'><CiUser /></span>
                                <span className='pt-1'> LOG IN </span>
                                <span className='px-2'><BiChevronDown /></span>
                            </div>
                            {/* <Menu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                                className='p-4'
                            >
                                <div className='p-2'>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="name@example.com *" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="password.ControlInput1">
                                            <Form.Control type="password" placeholder="password *" />
                                        </Form.Group>
                                        <div className='m-auto fit-to-contentBtn'>
                                            <Button className='m-auto w-100 rounded-0 my-2' variant="contained">Login</Button>
                                        </div>

                                    </Form>
                                    <div className='text-center'>
                                        <span className='bg-info py-1 px-3 rounded-1 text-white ' style={{ fontSize: "10px" }}>FORGOT YOUR PASSWORD ?</span>
                                    </div>
                                    <div className='text-center'>
                                        <span className='' style={{ fontSize: "12px" }}>NO ACCOUNT? CREATE ONE HERE</span>
                                    </div>
                                </div>
                            </Menu> */}
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    )
}
export default TopHeader
