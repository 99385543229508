import React from 'react'
import AboutUs from '../../components/aboutUs/AboutUs'

function AboutusPage() {
  return (
    <>
      <AboutUs/>
    </>
  )
}

export default AboutusPage
