import React from 'react'
import tablate from '../../../assets/img/Tablet - Two Bands - Push Button - No logo.webp'
import watchgif from '../../../assets/img/products/LinkWear Restaurant Messages Infographic Band Short.gif'
import './IntroducingLinkWare.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
function IntroducingLinkWare() {
    const {t} = useTranslation()
    return (
        <section className=''>
            <div className='container-fluid p-0'>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-12'>
                        <div className='text-center link-ware bg-section p-3'>
                            <h2>{t('introducining')}</h2>
                            <h6>
                            {t('Loremintroducining')}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid bg-tablet'>
                <div className='row'>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <div className=''>
                            <figure>
                                <img src={tablate} alt='' width={75 + '%'} />
                            </figure>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <div className='m-auto paraFont h-100' style={{ width: '65%' }}>
                            <div className='d-flex align-items-center h-100'>
                                <div className=''>
                                    <p>{t('wearable')}</p>
                                    <h5>
                                        <Link className='text-decoration-none'>
                                        {t('learnMore')}
                                        </Link>
                                    </h5>
                                    <picture>
                                    <img src={watchgif}alt=''/>
                                    </picture>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IntroducingLinkWare
