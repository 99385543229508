import React, { useEffect, useState } from 'react'
// mui
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import blog1 from "../../../assets/img/blogs/1.png"

import './BlogDetails.css'
import BlogLeaveComment from './blogLeaveComment/BlogLeaveComment';
import { base_url } from '../../../server';
import { useParams } from 'react-router-dom';
import axios from 'axios';
function BlogDetails() {
    const params = useParams()
    const [state, setState] = useState(null)
    const getBlogDetails = async (id) => {
        console.log(id.uid);
        const response = await axios.get(`${base_url}blogs/${id?.uid}`, { withCredentials: true })
        setState(response.data)
    }
    useEffect(() => {
        getBlogDetails(params)
    }, [])
    console.log(state);
    return (
        <section>
            <div className='container'>
                <div className='row py-5'>
                    <div className='col-md-10 col-lg-9 col-12'>
                        <div className=''>
                            <picture >
                                <img src={state?.banner?.url} alt='blog' className='w-100' />
                            </picture>
                            <figcaption>
                                <h4 className='border-bottom'>{state?.title}</h4>
                            </figcaption>
                        </div>

                        <div className=''>
                            <span><b>“ sometimes on purpose ”</b></span>
                            <p className='text-primary'>
                                {state?.short_description}
                            </p>
                        </div>

                    </div>

                    <div className='col-md-2 col-lg-3 col-12'>
                        <div className='border rounded-0 text-center text-uppercase '>
                            <h4>Blog categories</h4>
                        </div>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            aria-label="contacts"
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <StarIcon />
                                    </ListItemIcon>

                                    <ListItemText primary="Chelsea Otakan" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <div className='border rounded-0 text-center text-uppercase '>
                            <h4>Recent Posts Products</h4>
                        </div>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            aria-label="contacts"
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <picture>
                                        <img src={state?.banner?.url} alt='recentpost' className='w-100' />
                                    </picture>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <picture>
                                        <img src={state?.banner?.url} alt='recentpost' className='w-100' />
                                    </picture>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Chelsea Otakan" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8 col-lg-8 col-12'>
                        <BlogLeaveComment />
                    </div>
                    <div className='col-md-4 col-lg-4 col-d-none'>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetails
