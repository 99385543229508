import React from 'react'
import Ratings from './rating/Rating'
import Counter from '../../../common/counter/Counter'
import { FaShoppingCart } from 'react-icons/fa'
import { PiBagFill } from 'react-icons/pi'
import { Box, Button, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { BiSolidRightArrow } from 'react-icons/bi'
import { PiVideoFill } from 'react-icons/pi'

function ProductsContentDetails({ productDetails }) {
    const proGalleryImage = productDetails
    const productDetail = proGalleryImage?.product
    const ratSku = proGalleryImage?.product?.variations[0]

    return (
        <div className='col-md-6 col-lg-6 col-12 px-5 border'>
            <span className='d-flex align-items-center' >
                <Ratings ratSku={ratSku} />
            </span>
            <h4 className='productsTitle'>{productDetail?.name}</h4>
            <div className='price'>
                {ratSku &&
                    <span className='text-muted'>Price : <symbol className='text-decoration-line-through'>AED {''}</symbol>{ratSku?.prices[0].mrp}</span>}
                &nbsp;
                &nbsp;
                {ratSku &&
                    <span>Offer Price : <symbol>AED {''}</symbol>{ratSku?.prices[0].sale_rate}</span>}
                <Box>
                    <nav aria-label="secondary mailbox folders">
                        <List>
                            <ListItem disablePadding className='border my-1'>
                                <ListItemButton>
                                    <ListItemText primary="[DOWNLOAD] User Manual" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding className='border my-1'>
                                <ListItemButton component="a" href="#simple-list">
                                    <ListItemText primary="[DOWNLOAD] Programming Software" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                </Box>
                <div className=''>
                    <span>
                        <strong>QUANTITY</strong>
                    </span>
                    <div className='w-25  p-2' style={{ backgroundColor: "#80808087" }}><Counter /></div>
                </div>
                {/* <h6 className='p-2'>Descriptions <BiSolidRightArrow /></h6> */}
                <details>
                    <summary className='p-2'><b>Descriptions....</b></summary>
                    <div>
                        <ul className='p-0 m-0 ' style={{ listStyle: 'none', color: 'gray' }}>
                            <li>
                                <p>▸ Lorem Ipsum is simply dummy </p>
                            </li>
                            <li>
                            <p>▸ text of the printing and typesetting industry. Lorem Ipsum </p>
                        </li>
                        <li>
                            <p>▸ when an unknown printer took a galley of type and scrambled  </p>
                        </li>
                        <li>
                            <p>▸ Why do we use it?
                                It is a long established fact that a reader  </p>
                        </li>
                        </ul>
                    </div>
                </details>
                <Button className='my-4' variant="contained" startIcon={<FaShoppingCart />}>
                    ADD TO CART
                </Button>
                <Button className='m-4' variant="outlined" endIcon={<PiBagFill />}>
                    BUY NOW
                </Button>
            </div>
            <Button className='my-4 w-100' variant="outlined" startIcon={<PiVideoFill />}>
                Live Demo
            </Button>
        </div>
    )
}

export default ProductsContentDetails
