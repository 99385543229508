import React, { useEffect } from 'react'
import Breadcrums from '../../common/breadcrums/Breadcrums'
import aboutbanner from '../../assets/img/aboutus/Office-2.jpg'
import aboutbanner0 from '../../assets/img/aboutus/b464e3c1c66d0843cfa269409d34cfa5_2088df92-87ce-41a1-ae76-a58fceb701bb_800x.jpg'
import { useTranslation } from 'react-i18next'
function AboutUs() {
  const breadcrumsTitle = {
    id: '1', title: 'About Us'
  }
  const {t} = useTranslation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <div>
      <Breadcrums breadcrumsTitle={breadcrumsTitle} />
      <section className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-12'>
              <p>{t('aboutComp.fthElectronics')}</p>
            </div>
            <div className='col-lg-12 col-md-12 col-12'>
              <p>{t('aboutComp.syscall')}</p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-12'>
              <picture>
                <figure>
                  <img src={aboutbanner} alt='' className='w-100' />
                </figure>
              </picture>
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
              <h4 className='text-primary'>{t('aboutComp.majorwireLess')}</h4>
              <ul className=''>
                <li>{t('aboutComp.majorwireLess')}</li>
                <li>{t('aboutComp.allinone')}</li>
                <li>{t('aboutComp.guestpaging')}</li>
                <li>{t('aboutComp.tablecall')}</li>
                <li>{t('aboutComp.nurse')}</li>
                <li>{t('aboutComp.office')}</li>
                <li>{t('aboutComp.beach')}</li>
                <li>{t('aboutComp.server')}</li>
                <li>{t('aboutComp.staff')}</li>
                <li>{t('aboutComp.fast')}</li>
                <li>{t('aboutComp.customer')}</li>
                <li>{t('aboutComp.restaurant')}</li>
                <li>{t('aboutComp.hotel')}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5 col-lg-6 col-12'>
              <p>{t('aboutComp.ourproductwidily')}</p>
              <h2 className='text-primary'>{t('aboutComp.mission')}</h2>
              <p>{t('aboutComp.tobepreimer')}</p>
            </div>
            <div className='col-md-7 col-lg-6 col-12'>
              <div className='px-5'>
                <h4 className=''><strong>{t('aboutComp.pagertomake')}</strong></h4>
                <picture >
                  <figure>
                    <img src={aboutbanner0} alt='about img' className='w-75' />
                  </figure>
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-12 col-12'>
              
            </div>
          </div>
        </div>
      </section> */}
    </div>
  )
}

export default AboutUs
