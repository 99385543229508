import React from 'react'
import './Banner.css'
function Banner({title}) {
  return (
    <section>
      <div className='bannerWrapper p-5'>
        <h3 className='text-light text-center font-weight-bold' style={{
          fontFamily: 'Anton',letterSpacing:"5px",
        }}>
          {title}</h3>
      </div>
    </section >
  )
}

export default Banner
