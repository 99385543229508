import React, { useState } from 'react'
import "./Counter.css"
import { Button } from '@mui/material'
function Counter() {
    const [count, setCount] = useState(1)
    const handlecountDec = () => {
        setCount(count - 1)
    }
    const handlecountInc = () => {
        setCount(count + 1)
    }
    return (
        <div class="counter">
            <Button variant="" size="small" className='up fs-5' onClick={handlecountDec}><b>-</b>
            </Button>
            <input type="text" value={count} />
            <Button variant='' size="small" className='down fs-5' onClick={handlecountInc}><b>+</b></Button>
        </div>
    )
}

export default Counter
