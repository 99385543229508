import React, { useEffect } from 'react'
import Banner from '../../common/banner/Banner'
import CategoryAside from '../../common/categoryAside/CategoryAside'
import {Layout } from 'antd';
import ProductsCategoryItems from './productCategoryItems/ProductsCategoryItems';
import ProductCategoryHeader from '../../common/productCategoryHeader/ProductCategoryHeader';


    
function ProductCategory() {
  const title= "ANALOG"
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  })
  return (
    <section>
        <Banner title={title}/>
      <div className='container'>
      <Layout>
        <CategoryAside/>
        <Layout>
            <ProductCategoryHeader/>
        <ProductsCategoryItems/>
        {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer> */}
      </Layout>
      </Layout>
      </div>
    </section>
  )
}

export default ProductCategory
