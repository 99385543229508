import React from 'react'
// css start
import './ContactsByEmail.css'
// css end
// mui start
import { Button } from '@mui/material'
import {AiFillRedditCircle} from 'react-icons/ai';
// mui end

import { BiLogoFacebookCircle } from 'react-icons/bi'
import { useTranslation } from 'react-i18next';

function ContactsByEmail() {
    const {t} = useTranslation()
    return (
        <section>
            <div className='newsBg p-5'>
                <div className='newLetter'>
                    <div className='heading'>
                        <h3>
                            {t('subscribe')}
                        </h3>

                    </div>
                    <div className='paragraph'>
                        <p>
                        {t('exclusive')}
                        </p>
                    </div>

                    <div className='bg-light rounded p-2 enterEmail'>
                        <span><input className='emailInp' placeholder='Enter Your Email Address'  value={""} / ></span>
                        <Button className='ms-4' variant="contained"> {t('susbtn')}</Button>
                    </div>
                    <div className='text-center ' style={{fontSize:'42px'}}>
                        <span className='p-2'>
                            <BiLogoFacebookCircle/>
                        </span>
                        <span className='p-2'>
                            <AiFillRedditCircle/>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactsByEmail
