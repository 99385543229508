import React, { useEffect } from 'react'
import TermCodWrapp from './termAndCond/TermCodWrapp'
import Breadcrums from '../../common/breadcrums/Breadcrums'
import TermAndCond from './temAndCond/TermAndCond'

function TermAndConditions() {
    const breadcrumsTitle = {
        id: '1', title: 'TERM & CONDITION'
    }
    useEffect(()=>{
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    },[])
    return (
        <>
            <Breadcrums breadcrumsTitle={breadcrumsTitle} />
            <TermCodWrapp />
            <TermAndCond/>
        </>
    )
}

export default TermAndConditions
