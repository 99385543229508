import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import "../navigation/Navigation.css"
import logo from "../../assets/img/iradiosLogo-removebg.png"
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import { base_url } from '../../server';

// for Translation i18next START
import { useTranslation } from 'react-i18next';
// for Translation i18next end

function Navigation() {
  const [categData, setCategData] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  // use for Translation start 
  const { t } = useTranslation()
  // use for Translation end 

  const getNavigationCategory = async () => {
    try {
      const response = await axios.get(`${base_url}category/filter`, { withCredentials: true })
      setCategData(response.data)

    } catch (error) {
      setErrorMessage(error?.message)
    }

  }
  useEffect(() => {
    getNavigationCategory()
  }, [])
  return (
    <div className='position-sticky top-0 navigationTop'>
      <Navbar expand="lg" className="bg-body-tertiary ">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt='logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto fw-bold textNavigation">
              <Link className='text-decoration-none' to="/home">
               {t('home')} 
              </Link>
              <Link className='text-decoration-none' to="/about-us">
                {t('about')} 
                </Link>
              {errorMessage && ""}
              {categData && categData?.map((item, i) => {
                return <Link className='text-decoration-none' to={`/category/${item?.title?.uid}/${item?.title?.slug}`} key={i}>
                  {item?.title?.name}<span><ArrowDropDownIcon /></span>
                  <div className='dropDown '>
                    {item?.Submenu?.length > 0 && item?.Submenu?.map((item, i) => {
                      return <Link to={`/category/${item?.uid}/${item?.slug}`} key={i}>
                        {item?.name}
                      </Link>
                    })}
                  </div>
                </Link>
              })}
              <Link className='text-decoration-none' to="/oem-request">{t('contactus')}</Link>
              <Link className='text-decoration-none' to="/blogs">{t('news')}</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Navigation
