import React from 'react'
import ProductsBoughtTogether from './productsBoughtTogether/ProductsBoughtTogether'

function ProductionsDescriptions() {
  return (
    <div className='container'>
      <ProductsBoughtTogether/>
    </div>
  )
}

export default ProductionsDescriptions
