import React from 'react'
import ProductCategory from '../../components/category/ProductCategory'

function CategoryPages() {
  return (
    <>
      <ProductCategory/>
    </>
  )
}

export default CategoryPages
