import React from 'react'
import './TermCondWrap.css'
function TermCodWrapp() {
    return (
        <section className='termConditionWrap'>
            <div className='container'>
            </div>
        </section>
    )
}

export default TermCodWrapp
