import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { Button } from "@mui/material";
import './DetailsAndReview.css'
import DetailsNeedsobj from "./detailsNeed/DetailsNeedsobj";
import CustomerReview from "./customerReview/CustomerReview";
import DetailsRelatedsProducts from "./relatedProducts/DetailsRelatedsProducts";
import AskQuestions from "./customerReview/reviewQuestions/askQustions/AskQuestions";

function DetailsAndReview() {
    return (
        <Tabs className="w-100" defaultValue={0}>
            <TabsList className="bg-light rounded p-3">
                <Tab className="bg-primary tabBtn rounded" value={0}>DETAILS</Tab>
                <Tab className="bg-primary tabBtn rounded" value={1}>REVIEWS</Tab>
            </TabsList>
            <TabPanel value={0} className="my-4">
                <div className=" py-3 px-2">
                    <span className="text-muted">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </span>
                    <div className="">
                        <div className=" m-auto" style={{ width: 'fit-content' }}>
                            <span className="text-decoration-upperCase p-4 d-block ">
                                <b className="text-muted">
                                    Lorem Ipsum is simply dummy text
                                </b>
                            </span>
                        </div>
                    </div>
                    <div className="w-75 m-auto">
                        <iframe width="100%" height="355" src="https://www.youtube.com/embed/pOcx41KKPp8?si=6amfKIYEQo8qHIMO&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                <section className="py-3 my-3">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-12">
                            <DetailsNeedsobj />
                        </div>
                    </div>
                </section>
                <section className="py-3 my-3">
                    <CustomerReview />
                </section>
            </TabPanel>
            <TabPanel value={1} className="my-4">
                <div className=" py-5">
                    <DetailsRelatedsProducts />
                </div>
                <div className="">
                    <CustomerReview/>
                </div>
            </TabPanel>
        </Tabs>
    );
} export default DetailsAndReview





















